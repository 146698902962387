import React, { StrictMode } from 'react'
import { render } from 'react-dom'
import * as serviceWorker from './serviceWorker'

import './styles/style.scss'
import App from './App'

const rootElement = document.getElementById('root')
render(
    <StrictMode>
        <App />
    </StrictMode>,
    rootElement
)

serviceWorker.register()