import React from 'react'
import QuoteBox from './QuoteBox'

export default () => {
	const quote = ['He/Him', 'JS Enthusiast', 'Frontend Freak', 'Nerd', 'HTML aficionado', 'CSS circumspect', 'SCSS Lover']
	return (
		<div className="home">
			<QuoteBox text={quote} />
		</div>
	)
}
