import React from 'react'
import { NavLink } from 'react-router-dom'

export default () => {
	return (
		<div className="nav">
			<div className="nav-content">
				<h1 className="logo">Dhanush Adithya</h1>
				<ul>
					<li><NavLink exact to="/">Home</NavLink></li>
					<li><NavLink to="/bio">Bio</NavLink></li>
					<li><NavLink to="/contact">Contact</NavLink></li>
				</ul>
			</div>
		</div>
	)
}
