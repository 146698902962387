import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Sidebar, Navbar, Home, Bio } from './components'

export default function App() {
    const [isOpen, setisOpen] = useState(false)
    return (
        <Router>
            <Navbar />
            <Sidebar isOpen={isOpen} />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/bio" component={Bio} />
            </Switch>
        </Router>
    )
}
