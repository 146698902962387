import React from 'react'

export default () => {
	return (
		<div className="bio">
			<div>
				<h1>Hello There 👋</h1>
				<p>
					I'm Dhanush Adithya. 
					Lorem ipsum dolor sit amet consectetur adipisicing elit. 
					Hic veniam expedita, provident dolorum soluta nulla voluptate sit architecto. 
					Minus, beatae. Distinctio eaque iusto illum, tempore et voluptatum repellat nemo ipsum.
				</p>
			</div>
		</div>
	)
}